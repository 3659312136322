import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import HeaderAndText from "@components/header_with_text"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import ChildInfoForm from "@components/form_child_info_pledge"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const PledgeChildInfoPage = () => {
  const { childInfo } = data
  return (
    <Layout type="gray" pageClass="pledge">
      <ProgressBar />
      <BackButton data={childInfo.backLink} />
      <SEO title={childInfo.title} />
      <HeaderAndText data={childInfo.heading} />
      <ChildInfoForm />
    </Layout>
  )
}

export default PledgeChildInfoPage
