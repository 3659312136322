import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import InputPhone from "@components/_molecules/input_phone"
import * as data from "@data/pledge"
import { validate } from "@data/validations"
import * as progress from "@services/progress"
import * as errors from "@services/errors"
import InputDate from "@components/_molecules/input_date"

class ChildInfoForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      legalname: "",
      legalnameErrorText: "Needs both first and last name",
      legalnameErrorStatus: false,
      ssn: "",
      ssnErrorText: "Invalid SSN",
      ssnErrorStatus: false,
      dob: "",
      dobErrorText: "Invalid date of birth",
      dobErrorStatus: false,
      phonenumber: "",
      phonenumberErrorStatus: false,
      phonenumberErrorText: "Invalid phone number",
      buttonSubmitted: false,
      beneficiaryId: "",
      learnMore: false,
      beneficiaryToken: null,
      generalError: null
    }
  }

  componentDidMount() {
    let _this = this
    let { childName, childDob, beneficiaryToken } = localStorage
    this.setState({
      legalname: childName || ``,
      dob: childDob || ``,
      beneficiaryToken
    })
    let beneficiaryIdQuery = {
      query: `query {
        user {
          beneficiaries {
            id
            phone
          }
        }
      }`
    }
    const options = {
      method: "POST",
      data: JSON.stringify(beneficiaryIdQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          if (res.data.data.user.beneficiaries.length) {
            let beneficiaryId = res.data.data.user.beneficiaries[0].id,
              phonenumber = res.data.data.user.beneficiaries[0].phone.replace(
                "+",
                ""
              )
            _this.setState({
              beneficiaryId,
              phonenumber
            })
            window.localStorage.setItem(`beneficiaryId`, beneficiaryId)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  onDateChange(event) {
    this.setState({ dob: event.target.value })
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    let _this = this //needed to avoid losing context during axios/fetch call
    let phone = this.state.phonenumber.replace(/-/gi, "")
    let legalnameErrorStatus = !validate.name.test(this.state.legalname),
      ssnErrorStatus = !validate.ssn.test(this.state.ssn),
      dobErrorStatus = !validate.dob.test(this.state.dob),
      phonenumberErrorStatus = !validate.phone.test(phone)
    this.setState({
      legalnameErrorStatus,
      ssnErrorStatus,
      dobErrorStatus,
      phonenumberErrorStatus
    })
    if (
      !legalnameErrorStatus &&
      !ssnErrorStatus &&
      !dobErrorStatus &&
      !phonenumberErrorStatus
    ) {
      _this.setState({ buttonSubmitted: true })
      let dobArr = _this.state.dob.split("/")
      if (this.state.beneficiaryId) {
        let beneficiaryUpdate = {
          query: `mutation($updateBeneficiaryUserInput: UpdateBeneficiaryUserInput!){
          updateBeneficiaryUser(input: $updateBeneficiaryUserInput){
            user {
              id
              name
              birthdate
            }
          }
        }`,
          variables: {
            updateBeneficiaryUserInput: {
              beneficiaryUserId: _this.state.beneficiaryId,
              user: {
                name: _this.state.legalname,
                ssn: _this.state.ssn,
                birthdate: `${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`
              }
            }
          }
        }
        const updateOptions = {
          method: "POST",
          data: JSON.stringify(beneficiaryUpdate),
          headers: headers.getHeaders(),
          url: headers.api
        }
        axios(updateOptions)
          .then(result => {
            if (result.data.errors) {
              _this.errorHandler(result.data.errors[0].message)
            } else {
              if (typeof window !== `undefined`) {
                localStorage.setItem("childName", _this.state.legalname)
                localStorage.setItem("childDob", _this.state.dob)
              }
              progress.updateProgress()
              window.location = `/pledge/register/${progress.getNext()}`
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        let createBeneficiary = {
          query: `mutation($createBeneficiaryUserInput: CreateBeneficiaryUserInput!){
            createBeneficiaryUser(input: $createBeneficiaryUserInput){
              user {
                id,
                token
              }
            }
          }`,
          variables: {
            createBeneficiaryUserInput: {
              user: {
                phone: _this.state.phonenumber,
                name: _this.state.legalname,
                ssn: _this.state.ssn,
                birthdate: `${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`
              }
            }
          }
        }
        const createOptions = {
          method: "POST",
          data: JSON.stringify(createBeneficiary),
          headers: headers.getHeaders(),
          url: headers.api
        }
        axios(createOptions)
          .then(result => {
            if (result.data.errors) {
              _this.errorHandler(result.data.errors[0].message)
            } else {
              window.localStorage.setItem(
                `beneficiaryId`,
                result.data.data.createBeneficiaryUser.user.id
              )
              if (typeof window !== `undefined`) {
                localStorage.setItem("childName", _this.state.legalname)
                localStorage.setItem("childDob", _this.state.dob)
              }
              progress.updateProgress()
              window.location = `/pledge/register/${progress.getNext()}`
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
  onPhoneChange(event) {
    this.setState({ phonenumber: event.target.value })
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }
  render() {
    const { childInfo } = data
    return (
      <div className="child-form">
        <form onSubmit={this.handleSubmit}>
          <InputLabelState
            data={{
              name: `Legal name`,
              type: `text`,
              helpText: this.state.legalnameErrorText,
              errorStatus: this.state.legalnameErrorStatus,
              defaultVisibility: 0,
              value: this.state.legalname
            }}
            onChange={this.handleChange.bind(this)}
          />
          {console.log(this.state.learnMore)}
          <InputPhone
            name="Your child's phone number"
            value={this.state.phonenumber}
            helpText={this.state.phonenumberErrorText}
            defaultVisibility={0}
            errorStatus={this.state.phonenumberErrorStatus}
            onChange={this.onPhoneChange.bind(this)}
            isDisabled={this.state.beneficiaryToken ? true : false}
          />
          <div className="side-by-side">
            <InputLabelState
              data={{
                name: `SSN`,
                type: `password`,
                helpText: this.state.ssnErrorText,
                errorStatus: this.state.ssnErrorStatus,
                defaultVisibility: 0,
                value: this.state.ssn
              }}
              onChange={this.handleChange.bind(this)}
            />
            <InputDate
              name="DOB"
              options={{ blocks: [2, 2, 4], delimiter: "/", numericOnly: true }}
              value={this.state.dob}
              helpText={this.state.dobErrorText}
              defaultVisibility={0}
              errorStatus={this.state.dobErrorStatus}
              onChange={this.onDateChange.bind(this)}
            />
          </div>
          <div style={{ width: `100%`, height: `3rem` }}></div>
          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton
            data={childInfo.regButton}
            isDisabled={this.state.buttonSubmitted}
          />
        </form>
      </div>
    )
  }
}

export default ChildInfoForm
